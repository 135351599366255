import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getProfileContactInfoPending,
  getProfileContactInfoSuccess,
  getProfileContactInfoFailure,
  getCRMProfileContactInfoPending,
  getCRMProfileContactInfoSuccess,
  getCRMProfileContactInfoFailure,
} from "../slice/profileContactInfoSlice";

import {
  ProfileContactInfo,
  CRMProfileContactInfoPayload,
} from "../models/models";

const PROFILE_CONTACT_INFO_API_URL = config.PROFILE_CONTACT_UPDATE;
const CRM_PROFILE_CONTACT_INFO_API_URL = config.CRM_PROFILE_UPDATE;
const fetchProfileContactInfoData = async (
  ProfileContactInfoPayload: ProfileContactInfo
) => {
  let headerData = {
    headers: {
      memberId: sessionStorage.getItem("memberId"),
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    isGenericErr: true,
  };
  let profileContactInfoPayloadData = {
    updateCommunicationFlag: "Y",
    address: {
      addressType: ProfileContactInfoPayload.addressType,
      addressLine1: ProfileContactInfoPayload.addressLine1,
      ...(ProfileContactInfoPayload.addressLine2 && {
        addressLine2: ProfileContactInfoPayload.addressLine2,
      }),
      ...(ProfileContactInfoPayload.state && {
        state: ProfileContactInfoPayload.state,
      }),
      city: ProfileContactInfoPayload.city,
      countryCode: ProfileContactInfoPayload.countryCodeSecondary,
      postalCode: ProfileContactInfoPayload.postalCode,
    },
    phone: {
      countryCode: ProfileContactInfoPayload.countryCode,
      areaCode: ProfileContactInfoPayload.areaCode,
      number: ProfileContactInfoPayload.contactNumber?.replaceAll("-", ""),
      seqNo: ProfileContactInfoPayload.seqNo,
    },

    emailAddress: ProfileContactInfoPayload.email,
  };

  const response = await api.post(
    PROFILE_CONTACT_INFO_API_URL,
    profileContactInfoPayloadData,
    headerData
  );
  // if (response.status == 200) {
  //   sessionStorage.setItem("password", profileContactInfoPayloadData.pinNumber);
  //   console.log("fetchProfileContactInfoData_rESP",response.status)
  // }

  return response;
};

const fetchCRMProfileContactInfoData = async (
  CRMProfileContactInfoPayload: CRMProfileContactInfoPayload
) => {
  let headerData = {
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    isGenericErr: true,
  };
  let crmProfileContactInfoPayloadData = {
    countryCode: CRMProfileContactInfoPayload.countryCode,
    mobileNumber: CRMProfileContactInfoPayload.contactNumber,
  };

  const response = await api.post(
    CRM_PROFILE_CONTACT_INFO_API_URL,
    crmProfileContactInfoPayloadData,
    headerData
  );
  return response;
};

function* submitCRMProfileContactInfoData(
  action: PayloadAction<CRMProfileContactInfoPayload>
): Generator<any, void, any> {
  try {
    const response = yield call(fetchCRMProfileContactInfoData, action.payload);
    yield put(getCRMProfileContactInfoSuccess(response.data));
  } catch (error: any) {
    yield put(getCRMProfileContactInfoFailure(error));
  }
}

function* submitProfileContactInfoData(
  action: PayloadAction<ProfileContactInfo>
): Generator<any, void, any> {
  try {
    const response = yield call(fetchProfileContactInfoData, action.payload);
    yield put(getProfileContactInfoSuccess(response.data));
    console.log("response", response.data);
  } catch (error: any) {
    yield put(getProfileContactInfoFailure(error));
  }
}

export function* watchProfileContactInfoData(): any {
  yield takeLatest(
    getProfileContactInfoPending.type,
    submitProfileContactInfoData
  );
}

export function* watchCRMProfileContactInfoData(): any {
  yield takeLatest(
    getCRMProfileContactInfoPending.type,
    submitCRMProfileContactInfoData
  );
}
