import { call, put, takeEvery } from "redux-saga/effects";
import {
  resendOTPFail,
  resendOTPPending,
  resendOTPSuccess,
} from "../slice/resendSlice";
import api from "../../../config/api";
import { ResendOTPData } from "../models/models";
import { PayloadAction } from "@reduxjs/toolkit";

const API_URL = "/profile/otp/resend";
const resendOTPData = async (payload: ResendOTPData) => {
  let headerData = {
    headers: {
      accessToken: `${sessionStorage.getItem("temptoken")}`,
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "Authorization",
    },
  };
  let payloadData = {
    otpRef: payload.otpRef,
  };

  const response = await api.post(API_URL, payloadData, headerData);
  sessionStorage.setItem("temptoken", response.headers.accesstoken);
  return response;
};
function* resendOTPSaga(
  action: PayloadAction<ResendOTPData>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(resendOTPData, payload);
    yield put(resendOTPSuccess(response.data));
  } catch (error: any) {
    yield put(resendOTPFail(error));
  }
}

export function* watchResendOTP() {
  yield takeEvery(resendOTPPending.type, resendOTPSaga);
}
