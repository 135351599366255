import api from "../../../config/api";
import {
  tripDataRequest,
  getTripDataSuccess,
  getTripDataFailure,
  updateTripsPnrDetailsSuccess,
  updateTripsPnrDetailsFailure,
  updateTripsPnrDetailsRequest,
} from "../slice/tripSlice";
import { put, takeLatest, call } from "redux-saga/effects";
import { config } from "../../../config/global";
import { PayloadAction } from "@reduxjs/toolkit";

const fetchHeaderData = async (payload: any) => {
  const FETCH_API_URL = config.TEAMSITE_UPCOMING_TRIPS_API;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    loader: true,
  };
  const response = await api.get(FETCH_API_URL, data);
  return response;
};
const updateHeaderData = async (payload: any) => {
  const RETRIVE_PNR_API_URL = config.TEAMSITE_RETRIEVE_PNR_API;

  let headerData = {
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
  };
  let pnrPayload = {
    pnr: payload.pnr,
    lastName: payload.lastName,
    pastTrip: payload.pastTrip,
  };

  const response = await api.post(RETRIVE_PNR_API_URL, pnrPayload, headerData);
  return response;
};
function* getTripsData(action: PayloadAction<any>): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchHeaderData, payload);
    let modifiedResponse = {
      ...response.data,
      pnrDetails: response.data.pnrDetails.map(
        (pnrDetail: any, index: number) => {
          if (index > 2) {
            return { ...pnrDetail, flightDetails: [] };
          }
          return pnrDetail;
        }
      ),
    };

    yield put(getTripDataSuccess(modifiedResponse));
  } catch (error: any) {
    yield put(getTripDataFailure(error.message));
  }
}

function* updatePNRDetailsData(action: PayloadAction<any>): any {
  try {
    const { payload } = action;
    const response: any = yield call(updateHeaderData, payload);
    yield put(
      updateTripsPnrDetailsSuccess({
        payload: response.data.flightDetails,
        index: payload.cardId,
      })
    );
  } catch (error: any) {
    yield put(updateTripsPnrDetailsFailure(error.message));
  }
}
export function* watchManageBookingData(): any {
  yield takeLatest(tripDataRequest.type, getTripsData);
}
export function* watchUpdatePNRDetailsSaga(): any {
  yield takeLatest(updateTripsPnrDetailsRequest.type, updatePNRDetailsData);
}
