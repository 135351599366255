import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getRopSignUpDataPending,
  getRopSignUpSuccess,
  getRopSignupFailure,
} from "../slice/ropSignUpSlice";

import { ROPEnrollmentData } from "../models/models";

const ROP_SIGNUP_API_URL = "profile/enroll/add-rop-member";
const fetchRopSignupData = async (ropPayload: ROPEnrollmentData) => {
  let RopSignupPayloadData = {
    salutation: ropPayload.title,
    firstName: ropPayload.firstName,
    lastName: ropPayload.lastName,
    gender: ropPayload.gender,
    address: {
      addressType: "H",
      addressLine1: ropPayload.addressLine1,
      ...(ropPayload.addressLine2 && {
        addressLine2: ropPayload.addressLine2,
      }),
      city: ropPayload.city,
      state: ropPayload.state,
      countryCode: ropPayload.countryCodeSecondary,
      postalCode: ropPayload.postalCode,
    },
    phone: {
      countryCode: ropPayload.countryCode,
      areaCode: "0",
      number: ropPayload.contactNumber?.replaceAll("-", ""),
    },

    emailAddress: ropPayload.email,
    birthDate: ropPayload.dob,
    enrollSource: "INET",
    language: ropPayload.languagePreference,
    sponsorID: ropPayload.referringMemberID,
    SSRSeat: ropPayload.seatPreference,
    SSRMeal: ropPayload.mealPreference,
    thaiOfferIndicator: ropPayload.ropNewsCheck,
    partnerOfferIndicator: ropPayload.thirdPartyCheck,
    pinNumber: ropPayload.createPassword,
  };
  let RopParentLoadPayloadData = {
    salutation: ropPayload.title,
    firstName: ropPayload.firstName,
    lastName: ropPayload.lastName,
    gender: ropPayload.gender,
    address: {
      addressType: "H",
      addressLine1: ropPayload.addressLine1,
      ...(ropPayload.addressLine2 && {
        addressLine2: ropPayload.addressLine2,
      }),
      city: ropPayload.city,
      countryCode: ropPayload.countryCodeSecondary,
      postalCode: ropPayload.postalCode,
    },
    phone: {
      countryCode: ropPayload.countryCode,
      areaCode: "0",
      number: ropPayload.contactNumber,
    },
    consent: {
      consentFlag: true,
      consentParentName: `${ropPayload.consentParentFirstName} ${ropPayload.consentParentLastName}`,
    },

    emailAddress: ropPayload.email,
    birthDate: ropPayload.dob,
    enrollSource: "INET",
    language: ropPayload.languagePreference,
    sponsorID: "",
    SSRSeat: ropPayload.seatPreference,
    SSRMeal: ropPayload.mealPreference,
    thaiOfferIndicator: ropPayload.ropNewsCheck,
    partnerOfferIndicator: ropPayload.thirdPartyCheck,
    pinNumber: ropPayload.createPassword,
    airportCode: ropPayload.cityCode,
  };
  const response = await api.post(
    ROP_SIGNUP_API_URL,
    ropPayload.consentParentFirstName != null
      ? RopParentLoadPayloadData
      : RopSignupPayloadData
  );
  if (response.status == 200) {
    sessionStorage.setItem("password", RopSignupPayloadData.pinNumber);
  }

  return response;
};

function* getROpSIgnupData(
  action: PayloadAction<ROPEnrollmentData>
): Generator<any, void, any> {
  try {
    const response = yield call(fetchRopSignupData, action.payload);
    yield put(getRopSignUpSuccess(response.data));
  } catch (error: any) {
    yield put(getRopSignupFailure(error));
  }
}

export function* watchRopSIgnupData(): any {
  yield takeLatest(getRopSignUpDataPending.type, getROpSIgnupData);
}
