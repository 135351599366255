import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchNomineeRequest,
  getNomineeSuccess,
  getNomineeFailure,
  addNomineeRequest,
  addNomineeSuccess,
  addNomineeFailure,
  removeNomineeRequest,
  removeNomineeSuccess,
  removeNomineeFailure,
} from "../../slice/fetchNomineeSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import { PayloadAction } from "@reduxjs/toolkit";
import { RemoveNomineeData } from "../../models/models";

const accessToken = sessionStorage.getItem("accesstoken");

const fetchNomineeData = async (payload: any) => {
  const FETCH_NOMINEE_API_URL = config.FETCH_NIOMINEE;
  const data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      memberId: payload,
      Authorization: accessToken,
    },
    loader: true,
    isGenericErr: true,
  };
  const response = await api.get(FETCH_NOMINEE_API_URL, data);
  return response;
};
function* getNomineeData(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(fetchNomineeData, payload);
    yield put(getNomineeSuccess(response.data));
  } catch (error: any) {
    yield put(getNomineeFailure(error.message));
  }
}

const addNominee = async (payload: any) => {
  const FETCH_NOMINEE_API_URL = config.FETCH_NIOMINEE;
  const data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: accessToken,
    },
    loader: true,
    isGenericErr: true,
  };
  const payloadData = {
    formOfPayment: "MILE",
    salutation: payload.title,
    firstName: payload.firstName,
    lastName: payload.lastName,
    middleName: payload.middleName,
    suffix: "",
    dateOfBirth: payload.birthDate,
    ropNumber: payload.royalOrchidPlusMemberId,
  };
  const response = await api.post(FETCH_NOMINEE_API_URL, payloadData, data);
  return response;
};

function* addNomineeData(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(addNominee, payload);
    yield put(addNomineeSuccess(response.data));
  } catch (error: any) {
    yield put(addNomineeFailure(error));
  }
}

const removeNomineeData = async (removeNomineePayload: RemoveNomineeData) => {
  const DELETE_NOMINEE_API_URL = config.FETCH_NIOMINEE;
  const requestInfo = {
    headers: {
      memberId: sessionStorage.getItem("memberId"),
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    loader: true,
    isGenericErr: true,
    data: {
      formOfPayment: removeNomineePayload.formOfPayment,
      nomineeID: removeNomineePayload.nomineeID,
      salutation: removeNomineePayload.salutation,
      firstName: removeNomineePayload.firstName,
      lastName: removeNomineePayload.lastName,
      middleName: removeNomineePayload.middleName,
      suffix: removeNomineePayload.suffix,
      dateOfBirth: removeNomineePayload.dateOfBirth,
    },
  };
  const response = await api.delete(DELETE_NOMINEE_API_URL, requestInfo);
  return response;
};

function* deleteNomineeData(
  action: PayloadAction<RemoveNomineeData>
): Generator<any, void, any> {
  try {
    const response = yield call(removeNomineeData, action.payload);
    yield put(removeNomineeSuccess(response.data));
  } catch (error: any) {
    yield put(removeNomineeFailure(error));
  }
}

export function* watchRemoveNomineeData(): any {
  yield takeLatest(removeNomineeRequest.type, deleteNomineeData);
}

export function* watchAddNomineeData(): any {
  yield takeLatest(addNomineeRequest.type, addNomineeData);
}

export function* watchNomineeData(): any {
  yield takeLatest(fetchNomineeRequest.type, getNomineeData);
}
