import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { NewsSubscribeData } from "../models/models";
import {
  getSubscribeDataPending,
  getSubscribeSuccess,
  getSubscribeFailure,
} from "../slice/subscribeSlice";

const SUBSCRIBE_RESET_URL = "common/subscription/subscribe";
const fetchSubscribeResetData = async (subscribePayload: NewsSubscribeData) => {
  let subscribePayloadData = {
    email: subscribePayload.email,
    dateOfBirth: subscribePayload.dateOfBirth,
    preferenceLocation: subscribePayload.preferenceLocation,
  };

  let headerData = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await api.post(
    SUBSCRIBE_RESET_URL,
    subscribePayloadData,
    headerData
  );
  return response;
};

function* getSubscribeData(
  action: PayloadAction<NewsSubscribeData>
): Generator<any, void, any> {
  try {
    const response = yield call(fetchSubscribeResetData, action.payload);
    yield put(getSubscribeSuccess(response.data));
  } catch (error: any) {
    yield put(getSubscribeFailure(error));
  }
}

export function* watchSubscribeData(): any {
  yield takeLatest(getSubscribeDataPending.type, getSubscribeData);
}
