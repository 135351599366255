import { ProfilePersonalInfo, CrmProfilePersonalInfo } from "../models/models";
import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  getProfilePersonalInfoPending,
  getProfilePersonalInfoSuccess,
  getProfilePersonalInfoFailure,
  getCrmProfilePersonalInfoPending,
  getCrmProfilePersonalInfoSuccess,
  getCrmProfilePersonalInfoFailure,
} from "../slice/profilePersonalInfoSlice";

const PROFILE_PERSONAL_INFO_API_URL = config.PROFILE_PERSONAL_INFO_UPDATE;
const CRM_PROFILE_CONTACT_INFO_API_URL = config.CRM_PROFILE_UPDATE;

const fetchProfileIPersonalnfoData = async (
  ProfilePersonalInfoPayload: ProfilePersonalInfo
) => {
  let headerData = {
    headers: {
      memberId: sessionStorage.getItem("memberId"),
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    isGenericErr: true,
  };

  const response = await api.post(
    PROFILE_PERSONAL_INFO_API_URL,
    ProfilePersonalInfoPayload,
    headerData
  );

  return response;
};

const fetchCrmProfileIPersonalnfoData = async (
  CrmProfilePersonalInfoPayload: CrmProfilePersonalInfo
) => {
  let headerData = {
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    isGenericErr: true,
  };

  const response = await api.post(
    CRM_PROFILE_CONTACT_INFO_API_URL,
    CrmProfilePersonalInfoPayload,
    headerData
  );

  return response;
};

function* submitProfilePersonalInfoData(
  action: PayloadAction<ProfilePersonalInfo>
): Generator<any, void, any> {
  try {
    const response = yield call(fetchProfileIPersonalnfoData, action.payload);
    yield put(getProfilePersonalInfoSuccess(response.data));
    console.log("response", response.data);
  } catch (error: any) {
    yield put(getProfilePersonalInfoFailure(error));
  }
}

function* submitCrmProfilePersonalInfoData(
  action: PayloadAction<CrmProfilePersonalInfo>
): Generator<any, void, any> {
  try {
    const response = yield call(
      fetchCrmProfileIPersonalnfoData,
      action.payload
    );
    yield put(getCrmProfilePersonalInfoSuccess(response.data));
  } catch (error: any) {
    yield put(getCrmProfilePersonalInfoFailure(error));
  }
}

export function* watchProfilePersonalInfoData(): any {
  yield takeLatest(
    getProfilePersonalInfoPending.type,
    submitProfilePersonalInfoData
  );
}
export function* watchCrmProfilePersonalInfoData(): any {
  yield takeLatest(
    getCrmProfilePersonalInfoPending.type,
    submitCrmProfilePersonalInfoData
  );
}
