import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchProfileRequest,
  getProfileSuccess,
  getProfileFailure,
  fetchCRMProfileRequest,
  getCRMProfileSuccess,
  getCRMProfileFailure,
  fetchCountryRequest,
  getCountrySuccess,
  getCountryFailure,
  fetchCityRequest,
  getCitySuccess,
  getCityFailure,
  fetchStateRequest,
  getStateSuccess,
  getStateFailure,
} from "../../slice/fetchProfileSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
const data = {
  mode: "no-cors",
  headers: {
    // memberId: sessionStorage.getItem("memberId"),
    Authorization: sessionStorage.getItem("accesstoken"),
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  loader: true,
};
const fetchProfileData = async () => {
  const FETCH_PROFILE_API_URL = config.FETCH_PROFILE;
  const response = await api.get(FETCH_PROFILE_API_URL, data);
  sessionStorage.setItem("privilegeCard", response.data.privilegeCard);
  return response;
};
function* getProfileData(): any {
  try {
    const response = yield call(fetchProfileData);
    yield put(getProfileSuccess(response.data));
  } catch (error: any) {
    yield put(getProfileFailure(error.message));
  }
}

const fetchCRMProfileData = async () => {
  const FETCH_CRM_PROFILE_API_URL = config.CRM_FETCH_PROFILE;
  const response = await api.get(FETCH_CRM_PROFILE_API_URL, data);
  return response;
};
function* getCRMProfileData(): any {
  try {
    const response = yield call(fetchCRMProfileData);
    yield put(getCRMProfileSuccess(response.data));
  } catch (error: any) {
    yield put(getCRMProfileFailure(error.message));
  }
}

const fetchCountryData = async () => {
  const FETCH_COUNTRIES = config.COUNTRY_DATA;
  const response = await api.get(FETCH_COUNTRIES, data);
  return response;
};
function* getCountryData(): any {
  try {
    const response = yield call(fetchCountryData);
    yield put(getCountrySuccess(response.data));
  } catch (error: any) {
    yield put(getCountryFailure(error.message));
  }
}

const fetchCityData = async () => {
  const FETCH_CITIES = config.CITY_DATA;
  const response = await api.get(FETCH_CITIES, data);
  return response;
};
function* getCityData(): any {
  try {
    const response = yield call(fetchCityData);
    yield put(getCitySuccess(response.data));
  } catch (error: any) {
    yield put(getCityFailure(error.message));
  }
}

const fetchStateData = async () => {
  const FETCH_STATES = config.STATE_DATA;
  const response = await api.get(FETCH_STATES, data);
  return response;
};
function* getStateData(): any {
  try {
    const response = yield call(fetchStateData);
    yield put(getStateSuccess(response.data));
  } catch (error: any) {
    yield put(getStateFailure(error.message));
  }
}

export function* watchProfileData(): any {
  yield takeLatest(fetchProfileRequest.type, getProfileData);
}

export function* watchCRMProfileData(): any {
  yield takeLatest(fetchCRMProfileRequest.type, getCRMProfileData);
}

export function* watchCountry(): any {
  yield takeLatest(fetchCountryRequest.type, getCountryData);
}

export function* watchCity(): any {
  yield takeLatest(fetchCityRequest.type, getCityData);
}

export function* watchState(): any {
  yield takeLatest(fetchStateRequest.type, getStateData);
}
