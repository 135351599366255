import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { NewsUnSubscribeData } from "../models/models";
import {
  getUnsubscribeDataPending,
  getUnSubscribeSuccess,
  getUnSubscribeFailure,
} from "../slice/unsubscribeSlice";

const UNSUBSCRIBE_RESET_URL = "common/subscription/unsubscribe";
const fetchUnsubscribeResetData = async (
  UnsubscribePayload: NewsUnSubscribeData
) => {
  let unsubscribePayloadData = {
    email: UnsubscribePayload.email,
  };

  let headerData = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await api.post(
    UNSUBSCRIBE_RESET_URL,
    unsubscribePayloadData,
    headerData
  );
  return response;
};

function* getUnsubscribeData(
  action: PayloadAction<NewsUnSubscribeData>
): Generator<any, void, any> {
  try {
    const response = yield call(fetchUnsubscribeResetData, action.payload);
    yield put(getUnSubscribeSuccess(response.data));
  } catch (error: any) {
    yield put(getUnSubscribeFailure(error));
  }
}

export function* watchUnsubscribeData(): any {
  yield takeLatest(getUnsubscribeDataPending.type, getUnsubscribeData);
}
