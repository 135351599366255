import { call, put, takeLatest } from "redux-saga/effects";

import api from "../../../config/api";

import {
  getAirportRedemptionDataSuccess,
  getAirportRedemptionDataFailure,
  airportRedemptionDataRequest,
} from "../slice/redemptionSlice";
const AIRPORT_API_REDEMPTION_URL = "/common/airport/air-award";
// const location_country = window.location.href.split("/")[3];
// const location = location_country.split("-")[0];

const data = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // "Accept-Language": location,
  },
  body: {},
};

function* getAirportRedemptionData(): any {
  try {
    const response: any = yield call(api.get, AIRPORT_API_REDEMPTION_URL, data);
    console.log("data", response.data);

    yield put(getAirportRedemptionDataSuccess(response.data));
  } catch (error: any) {
    yield put(getAirportRedemptionDataFailure(error.message));
  }
}

export function* watchAirportRedemptionData(): any {
  yield takeLatest(airportRedemptionDataRequest.type, getAirportRedemptionData);
}
