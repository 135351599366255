import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import {
  resetDataFailure,
  resetDataRequest,
  resetDataSuccess,
} from "../slice/resetSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ResetFormData } from "../../src/models/models";
import { config } from "../../../config/global";

// API Post URL for Reset Password
const Reset_API_URL = config.UNLOCK_ACCOUNT_API;

const fetchResetData = async (resetPayload: ResetFormData) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
    },
  };
  let resetPayloadData = {
    memberId: resetPayload.memberId?.toUpperCase(),
    emailAddress: resetPayload.emailAddress,
    surname: resetPayload.surname.toUpperCase(),
    birthDate: resetPayload.birthDate,
  };

  const response = await api.post(Reset_API_URL, resetPayloadData, data);
  return response;
};

// Get data generator function
function* getReset(action: PayloadAction<ResetFormData>): any {
  const { payload } = action;
  try {
    const response = yield call(fetchResetData, payload);
    yield put(resetDataSuccess(response.data));
  } catch (error: any) {
    yield put(resetDataFailure(error.response.data));
  }
}

export function* watchReset(): any {
  yield takeLatest(resetDataRequest.type, getReset);
}
