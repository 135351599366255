import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";
import {
  retrivePNRFail,
  retrivePNRRequest,
  retrivePNRSuccess,
} from "../slice/retrivePNRSlice";

const API_URL = config?.RETRIVE_PNR;
const fetchRetrivePNR = async (payload: any) => {
  let headerData = {
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website",
    },
    loader: true,
  };

  const response = await api.post(API_URL, payload, headerData);

  return response;
};
function* retrivePNRSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchRetrivePNR, payload);
    yield put(
      retrivePNRSuccess({
        ...response,
      })
    );
  } catch (error: any) {
    yield put(
      retrivePNRFail({
        ...error?.response,
      })
    );
  }
}

export function* watchRetrivePNRData() {
  yield takeEvery(retrivePNRRequest.type, retrivePNRSaga);
}
