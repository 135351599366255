import api from "../../../config/api";
import { addMyTripPayload } from "../models/models";
import {
  myTripsDataRequest,
  getMyTripsDataSuccess,
  getMyTripDataFailure,
  postAddTripPending,
  postAddTripSuccess,
  postAddTripFailure,
  myTripsPnrRequest,
  getPnrDataSuccess,
  getPnrDataFailure,
  myTripsCheckinRequest,
  postCheckInDataSuccess,
  postCheckInDataFailure,
  retrieveTripsRequest,
  retrieveTripsRequestSuccess,
  retrieveTripsRequestFailure,
} from "../slice/myTripsSlice";
import { put, takeLatest, call } from "redux-saga/effects";
import { config } from "../../../config/global";
import { PayloadAction } from "@reduxjs/toolkit";

const fetchUpcomingTripData = async (myTripsPayload: any) => {
  const FETCH_API_URL = config.TEAMSITE_MY_TRIPS_UPCOMING_TRIPS_API;
  let myTripsPayloadData = {
    category: myTripsPayload.category,
  };

  const data = {
    mode: "no-cors",
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    loader: true,
    isGenericErr: true,
  };
  const response = await api.get(
    `${FETCH_API_URL}?category=${myTripsPayloadData.category}`,
    data
  );
  return response;
};

const fetchPnrData = async (retrievePnrPayload: any) => {
  const RETRIVE_PNR_API_URL = config.MY_TRIPS_RETRIEVE_API;
  let retrievePnrPayloadData = {
    isUpcoming: retrievePnrPayload.isUpcoming,
  };

  const data = {
    mode: "no-cors",
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    isGenericErr: true,
  };
  let pnrPayload = {
    pnr: retrievePnrPayload.pnr,
    lastName: retrievePnrPayload.lastName,
    pastTrip: retrievePnrPayload?.pastTrip,
  };
  const response = await api.post(
    `${RETRIVE_PNR_API_URL}?isUpcoming=${retrievePnrPayloadData.isUpcoming}`,
    pnrPayload,
    data
  );
  return response;
};

const updateHeaderData = async (payload: any) => {
  const RETRIVE_PNR_API_URL = config.TEAMSITE_RETRIEVE_PNR_API;

  let headerData = {
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
  };
  let pnrPayload = {
    pnr: payload.pnr,
    lastName: payload.lastName,
    pastTrip: payload.pastTrip,
  };

  const response = await api.post(RETRIVE_PNR_API_URL, pnrPayload, headerData);
  return response;
};

const fetchCheckInData = async (retrieveCheckIn: any) => {
  const RETRIVE_CHECKIN_API_URL = config.MY_TRIPS_CHECK_IN_API;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    loader: true,
    isGenericErr: true,
  };
  let checkinPayload = {
    id: retrieveCheckIn.pnr,
    lastName: retrieveCheckIn.lastName,
  };
  const response = await api.post(
    `${RETRIVE_CHECKIN_API_URL}`,
    checkinPayload,
    data
  );
  return response;
};

function* getCheckinData(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(fetchCheckInData, payload);
    yield put(postCheckInDataSuccess(response.data));
  } catch (error: any) {
    yield put(postCheckInDataFailure(error.message));
  }
}

function* getPnrData(action: PayloadAction<any>): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(fetchPnrData, payload);
    yield put(getPnrDataSuccess(response.data));
  } catch (error: any) {
    yield put(getPnrDataFailure(error.message));
  }
}

function* getUpcomingTripsData(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(fetchUpcomingTripData, payload);
    let modifiedResponse = {
      ...response.data,
      pnrDetails: response.data.pnrDetails.map(
        (pnrDetail: any, index: number) => {
          if (index > 2) {
            return { ...pnrDetail, flightDetails: [] };
          }
          return pnrDetail;
        }
      ),
    };
    yield put(getMyTripsDataSuccess(modifiedResponse));
  } catch (error: any) {
    yield put(getMyTripDataFailure(error.message));
  }
}

const fetchAddTripData = async (myTripsPayload: addMyTripPayload) => {
  const ADD_TRIP_API_URL = config.ADD_MY_TRIP_API;

  let data = {
    mode: "no-cors",
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    loader: true,
  };
  const response = await api.post(ADD_TRIP_API_URL, myTripsPayload, data);
  return response;
};

function* postAddTripsData(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(fetchAddTripData, payload);
    yield put(postAddTripSuccess(response.data));
  } catch (error: any) {
    yield put(postAddTripFailure(error));
  }
}

function* retrieveTripDetails(action: PayloadAction<any>): any {
  try {
    const { payload } = action;
    const response: any = yield call(updateHeaderData, payload);
    yield put(
      retrieveTripsRequestSuccess({
        payload: response.data.flightDetails,
        index: payload.cardId,
      })
    );
  } catch (error: any) {
    yield put(retrieveTripsRequestFailure(error.message));
  }
}

export function* watchUpcomingTripData(): any {
  yield takeLatest(myTripsDataRequest.type, getUpcomingTripsData);
}

export function* watchAddMyTripData(): any {
  yield takeLatest(postAddTripPending.type, postAddTripsData);
}
export function* watchPnrData(): any {
  yield takeLatest(myTripsPnrRequest.type, getPnrData);
}

export function* watchCheckinData(): any {
  yield takeLatest(myTripsCheckinRequest.type, getCheckinData);
}

export function* watchRetrieveTripData(): any {
  yield takeLatest(retrieveTripsRequest.type, retrieveTripDetails);
}
