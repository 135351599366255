import { all } from "redux-saga/effects";
import { watchFetchHomeData } from "./homeSaga";
import { watchFetchPromotionData } from "./homepage/promotionSaga";
import { watchGlobalSearchPreRenderData } from "./header/headerSaga";
import { watchFetchSecondaryCarouselData } from "./homeSecondaryCarouselSaga";
import { watchFetchTertiaryCarouselData } from "./homeTertiaryCarouselSaga";
import { watchAirportData } from "./bookingWidgetSaga";
import { watchPreferences } from "./preferenceSaga";
import { watchFetchROPData } from "./homepage/ropSaga";
import { watchGeolocationData } from "./header/geolocationSaga";
import { watchCountryData } from "./header/countrySaga";
import { watchFetchROHData } from "./homepage/rohSaga";
import { watchThaiCardData } from "./thaiCardApplicationSaga";
import { watchFetchPrivilegeData } from "./homepage/privilegeSaga";
import { watchAdvertisementData } from "./advertisementSaga";
import { watchFetchApplicationCardData } from "./applicationCardSaga";
import { watchTermsPrivacyModalData, watchNationData } from "./signUpSaga";
import { watchFetchData } from "./apiSaga";
import { watchLoginData } from "./loginSaga";
import { watchSendOTPData } from "./loginOTPSaga";
import { watchReset } from "./resetSaga";
import { watchFlightInfo } from "./flightInfoSaga";
import { watchFlightFare } from "./flightFareSaga";
import { watchResendOTP } from "./resendSaga";
import { watchCrmLoginData, watchCrmLoginDataValidation } from "./crmLoginSaga";
import { watchAirportRedemptionData } from "./redemptionSaga";
import { watchropData } from "./ropAignupSaga";
import { watchRopSIgnupData } from "./ropSignupSaga";
import {
  watchProfileData,
  watchCRMProfileData,
  watchCountry,
  watchCity,
  watchState,
} from "./profile/fetchProfileSaga";
import { watchNewsData } from "./newSaga";
import {
  watchRemoveNomineeData,
  watchAddNomineeData,
  watchNomineeData,
} from "./profile/fetchNomineeSaga";
import { watchCRMData } from "./resetCRMSaga";
import { watchLanguageData } from "./homepage/languageSaga";
import {
  watchCrmSignupData,
  watchCrmSignupSocialMediaData,
} from "./crmSignupSaga";
import { watchUnsubscribeData } from "./newsletterUnsubscribeSaga";
import { watchSubscribeData } from "./newslettersubscribeSaga";
import { watchFlightStatusByNumber } from "./flightStatusSaga";
import { watchFlightStatusByRoute } from "./flightStatusByRouteSaga";
import { watchCRMLogOutData, watchLogOutData } from "./logOutSaga";
import {
  watchManageBookingData,
  watchUpdatePNRDetailsSaga,
} from "./manageBookingSaga";
import { watchPrefernceFromData } from "./newsletterPreferenceSaga";
import { watchSendCRMOTPData } from "./crmOTPSaga";
import {
  watchProfileContactInfoData,
  watchCRMProfileContactInfoData,
} from "./profileContactInfoSage";

import {
  watchMainProfileData,
  watchCrmNationatityData,
} from "./profile/fetchMainMemberProfileSaga";
import { watchPNRDetailsSaga } from "./pnrDetailsSaga";
import { watchResubscribeData } from "./resubscribeSaga";
import { watchSendCalendarPriceData } from "./calendarPricingSaga";
import { watchMainProfileMilesData } from "./profile/fetchProfilesMilesSaga";
import { watchAirAwardBooking } from "./airAwardBookingSaga";
import { watchAirAwardPnr } from "./airAwardPnrSaga";
import { watchAirAwardPrice } from "./airAwardPriceSaga";
import { watchAirAwardTst } from "./airAwardTstSaga";
import { watchRetrivePNRData } from "./retrivePNRSaga";
import { watchCancelBookingPNRData } from "./cancelBookingPNRSaga";
import { watchMyLifeStyleData } from "./profile/fetchLifeStyleSaga";
import { watchProfileLifeStyleInfoData } from "./profileLifeStyleInfoSage";
import { watchAirAwardReviewPnr } from "./airAwardReviewPnrSaga";
import { watchDeleteTripCardData } from "./deleteTripCardSaga";
import { watchAirAwardAirport } from "./airAwardAirportSaga";
import { watchProfilePersonalInfoData } from "./profilePersonalInfoSaga";
import { watchCrmProfilePersonalInfoData } from "./profilePersonalInfoSaga";
import { watchCrmMemberProfileData, watchCrmCountryCodeData, 
  watchCrmnewsletterPreferenceData,watchCrmresidenceData } from "./profile/fetchCrmMemberProfileSaga";
import { watchChangePin } from "./changePasswordSaga";
import { watchChangePasswordSendOTPData } from "./changePasswordOTPSubmitSaga";
import { watchChangePasswordResendOTP } from "./changePasswordOTPResendSaga";
import { watchCrmResendOTP } from "./crmResendOTPSaga";
import {
  watchMileageStatementAwardDetailData,
  watchMileageStatementAwardListData,
  watchMileageStatementByDateData,
  watchMileageStatementData,
  watchMileageStatementReportData,
} from "./mileageStatementSaga";
import {
  watchUpdateEReceiptSaga,
  watchFetchEReceiptSaga,
} from "./eReceiptSaga";
import { watchAirAwardReviewPnrRM } from "./airAwardReviewPnrRMSaga";
import { watchAirAwardPaymentInit } from "./airAwardPaymentInitSaga";
import {
  watchUpcomingTripData,
  watchAddMyTripData,
  watchPnrData,
  watchCheckinData,
  watchRetrieveTripData,
} from "./myTripsSaga";
import { watchAirAwardReviewPnrCancel } from "./airAwardReviewPnrCancelSaga";
import { watchBackTrackingAirport } from "./backtrackingAirportSaga";
import {
  watchResendNomineeOTPData,
  watchSendNomineeOTPData,
  watchSubmitNomineeOTPData,
} from "./nomineeOTPsaga";
import {watchMemberTierCalclulatorData} from "./fetchMemberStatusCalculatorSaga"
import { watchGiftMilesDropdownData, watchGiftMilesReviewSummaryData } from "./giftMilesSaga";
import { watchAirAwardConfirmationData } from "./airAwardConfirmationSaga";

export default function* rootSaga() {
  yield all([
    watchFetchData(),
    watchFetchHomeData(),
    watchFetchPromotionData(),
    watchFetchSecondaryCarouselData(),
    watchFetchTertiaryCarouselData(),
    watchAirportData(),
    watchPreferences(),
    watchCountryData(),
    watchGeolocationData(),
    watchGlobalSearchPreRenderData(),
    watchFetchROPData(),
    watchFetchROHData(),
    watchThaiCardData(),
    watchFetchPrivilegeData(),
    watchAdvertisementData(),
    watchFetchApplicationCardData(),
    watchTermsPrivacyModalData(),
    watchNationData(),
    watchLoginData(),
    watchSendOTPData(),
    watchReset(),
    watchFlightInfo(),
    watchResendOTP(),
    watchCrmLoginData(),
    watchAirportRedemptionData(),
    watchRopSIgnupData(),
    watchropData(),
    watchProfileData(),
    watchCRMData(),
    watchNewsData(),
    watchNomineeData(),
    watchFlightFare(),
    watchLanguageData(),
    watchCrmSignupData(),
    watchUnsubscribeData(),
    watchSubscribeData(),
    watchFlightStatusByNumber(),
    watchFlightStatusByRoute(),
    watchLogOutData(),
    watchManageBookingData(),
    watchPrefernceFromData(),
    watchSendCRMOTPData(),
    watchProfileContactInfoData(),
    watchMainProfileData(),
    watchPNRDetailsSaga(),
    watchUpdatePNRDetailsSaga(),
    watchResubscribeData(),
    watchSendCalendarPriceData(),
    watchMainProfileMilesData(),
    watchAirAwardBooking(),
    watchAirAwardPnr(),
    watchAirAwardPrice(),
    watchAirAwardTst(),
    watchRemoveNomineeData(),
    watchAddNomineeData(),
    watchRetrivePNRData(),
    watchCancelBookingPNRData(),
    watchMyLifeStyleData(),
    watchProfileLifeStyleInfoData(),
    watchAirAwardReviewPnr(),
    watchCRMProfileData(),
    watchCountry(),
    watchCity(),
    watchState(),
    watchDeleteTripCardData(),
    watchAirAwardAirport(),
    watchProfilePersonalInfoData(),
    watchCrmMemberProfileData(),
    watchCrmLoginDataValidation(),
    watchCrmSignupSocialMediaData(),
    watchCRMProfileContactInfoData(),
    watchMileageStatementData(),
    watchCrmProfilePersonalInfoData(),
    watchChangePin(),
    watchChangePasswordSendOTPData(),
    watchChangePasswordResendOTP(),
    watchCrmResendOTP(),
    watchUpdateEReceiptSaga(),
    watchFetchEReceiptSaga(),
    watchMileageStatementAwardListData(),
    watchMileageStatementReportData(),
    watchMileageStatementAwardDetailData(),
    watchCRMLogOutData(),
    watchAirAwardReviewPnrRM(),
    watchAirAwardPaymentInit(),
    watchUpcomingTripData(),
    watchAirAwardReviewPnrCancel(),
    watchBackTrackingAirport(),
    watchMileageStatementByDateData(),
    watchAddMyTripData(),
    watchPnrData(),
    watchCheckinData(),
    watchCrmNationatityData(),
    watchCrmNationatityData(),
    watchRetrieveTripData(),
    watchSendNomineeOTPData(),
    watchResendNomineeOTPData(),
    watchSubmitNomineeOTPData(),
    watchCrmCountryCodeData(), 
    watchCrmnewsletterPreferenceData(),
    watchCrmresidenceData(),
    watchMemberTierCalclulatorData(),
    watchGiftMilesDropdownData(),
    watchGiftMilesReviewSummaryData(),
    watchAirAwardConfirmationData()
  ]);
}
