import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchMyProfileRequest,
  getMyProfileSuccess,
  getMyProfileFailure,
  fetchCRMNatinalityRequest,
  getCRMNatinalitySuccess,
  getCRMNatinalityFailure,
} from "../../slice/fetchMainMemberProfileSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
const data = {
  mode: "no-cors",
  headers: {
    // memberId: sessionStorage.getItem("memberId"),
    // Authorization: sessionStorage.getItem("accesstoken"),
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};
const fetchMyProfileData = async () => {
  const FETCH_MAIN_MEMBER_PROFILE_API_URL = config.FETCH_MAIN_MEMBER_PROFILE;
  const response = await api.get(FETCH_MAIN_MEMBER_PROFILE_API_URL, data);
  return response;
};
function* getProfileData(): any {
  try {
    const response = yield call(fetchMyProfileData);
    yield put(getMyProfileSuccess(response.data));
  } catch (error: any) {
    yield put(getMyProfileFailure(error.message));
  }
}

const fetchCRMNationalityData = async () => {
  const FETCH_CRM_NATIONALITY = config.CRM_NATIONALITY_API;
  const response = await api.get(FETCH_CRM_NATIONALITY, data);
  return response;
};
function* getCRMNationalityData(): any {
  try {
    const response = yield call(fetchCRMNationalityData);
    yield put(getCRMNatinalitySuccess(response.data));
  } catch (error: any) {
    yield put(getCRMNatinalityFailure(error.message));
  }
}

export function* watchMainProfileData(): any {
  yield takeLatest(fetchMyProfileRequest.type, getProfileData);
}
export function* watchCrmNationatityData(): any {
  yield takeLatest(fetchCRMNatinalityRequest.type, getCRMNationalityData);
}
