import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchCrmMemberProfileRequest,
  getCrmProfileSuccess,
  getCrmProfileFailure,
  fetchCRMCountryCodeRequest,
  getCRMCountryCodeSuccess,
  getCRMCountryCodeFailure,
  fetchCRMnewsletterPreferenceRequest,
  getCRMnewsletterPreferenceSuccess,
  getCRMnewsletterPreferenceFailure,
  fetchCRMresidenceRequest,
  getCRMresidenceSuccess,
  getCRMresidenceFailure,
} from "../../slice/fetchCrmProfileSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
const data = {
  mode: "no-cors",
  headers: {
    Authorization: sessionStorage.getItem("accesstoken"),
  },
};
const fetchCrmProfileData = async () => {
  const FETCH_CRM_MEMBER_PROFILE_API_URL = config.FETCH_CRM_MEMBER_PROFILE;
  const response = await api.get(FETCH_CRM_MEMBER_PROFILE_API_URL, data);
  return response;
};
function* getProfileData(): any {
  try {
    const response = yield call(fetchCrmProfileData);
    yield put(getCrmProfileSuccess(response.data));
  } catch (error: any) {
    yield put(getCrmProfileFailure(error.message));
  }
}

const fetchCRMCountryCodeData = async()=>{
  const FETCH_CRM_CountryCode = config.CRM_COUNTRYCODE_JSON;
  const response = await api.get(FETCH_CRM_CountryCode, data);
  return response;
}
function* getCRMCountryCodeData(): any {
  try {
    const response = yield call(fetchCRMCountryCodeData);
    yield put(getCRMCountryCodeSuccess(response.data));
  } catch (error: any) {
    yield put(getCRMCountryCodeFailure(error.message));
  }
}

const fetchCRMnewsletterPreferenceData = async()=>{
  const FETCH_CRM_newsletterPreference = config.CRM_NEWSLETTERPREFERENCE_JSON;
  const response = await api.get(FETCH_CRM_newsletterPreference, data);
  return response;
}
function* getCRMnewsletterPreferenceData(): any {
  try {
    const response = yield call(fetchCRMnewsletterPreferenceData);
    yield put(getCRMnewsletterPreferenceSuccess(response.data));
  } catch (error: any) {
    yield put(getCRMnewsletterPreferenceFailure(error.message));
  }
}

const fetchCRMresidenceData = async()=>{
  const FETCH_CRM_residence = config.CRM_RESIDENCE_JSON;
  const response = await api.get(FETCH_CRM_residence, data);
  return response;
}
function* getCRMresidenceData(): any {
  try {
    const response = yield call(fetchCRMresidenceData);
    yield put(getCRMresidenceSuccess(response.data));
  } catch (error: any) {
    yield put(getCRMresidenceFailure(error.message));
  }
}

export function* watchCrmresidenceData(): any {
  yield takeLatest(fetchCRMresidenceRequest.type, getCRMresidenceData);
}

export function* watchCrmnewsletterPreferenceData(): any {
  yield takeLatest(fetchCRMnewsletterPreferenceRequest.type, getCRMnewsletterPreferenceData);
}

export function* watchCrmCountryCodeData(): any {
  yield takeLatest(fetchCRMCountryCodeRequest.type, getCRMCountryCodeData);
}
export function* watchCrmMemberProfileData(): any {
  yield takeLatest(fetchCrmMemberProfileRequest.type, getProfileData);
}
