import { call, put, takeLatest } from "redux-saga/effects";

import api from "../../../config/api";

import { PayloadAction } from "@reduxjs/toolkit";
import {
  getMileageStatementDatePending,
  getMileageStatementFailure,
  getMileageStatementPending,
  getMileageStatementSuccess,
  getMilesAwardDetailDataPending,
  getMilesAwardDetailFailure,
  getMilesAwardDetailSuccess,
  getMilesAwardListDataPending,
  getMilesAwardListFailure,
  getMilesAwardListSuccess,
  getMilesStatementReportFailure,
  getMilesStatementReportPending,
  getMilesStatementReportSuccess,
} from "../slice/mileageStatementSlice";
import { config } from "../../../config/global";

const accessToken = sessionStorage.getItem("accesstoken");

const MileageStatementPeriod = async (payload: any) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
    },
    loader: true,
    isGenericErr: true,
  };
  let mileageStatementPayload = {
    filterBy: payload.filterData,
    month: payload.monthData,
    filterType: payload?.filterBy,
  };
  const response = await api.post(
    config.MILEAGE_STATEMENT,
    mileageStatementPayload,
    data
  );
  return response;
};

function* getMileageStatementByPeriodData(action: PayloadAction<any>): any {
  try {
    const { payload } = action;
    const response: any = yield call(MileageStatementPeriod, payload);
    yield put(getMileageStatementSuccess(response.data));
  } catch (error: any) {
    yield put(getMileageStatementFailure(error.message));
  }
}

const MileageAwardListHandler = async (payload: any) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
    },
    loader: true,
    isGenericErr: true,
  };
  let mileageStatementPayload = {};
  if (payload.filterData === "ByPeriod") {
    mileageStatementPayload = {
      filterBy: payload.filterData,
      month: payload.monthData,
      filterType: payload?.filterBy,
    };
  } else {
    mileageStatementPayload = {
      filterBy: payload.filterBy,
      startMonth: payload.startMonth,
      endMonth: payload.endMonth,
      month: payload.month, //need to check
      filterType: payload.filterType,
    };
  }
  const response = await api.post(
    config.MILEAGE_AWARD_LIST,
    mileageStatementPayload,
    data
  );
  return response;
};

function* getMileageAwardListDataGenerator(action: PayloadAction<any>): any {
  try {
    const { payload } = action;
    const response: any = yield call(MileageAwardListHandler, payload);
    yield put(getMilesAwardListSuccess(response.data));
  } catch (error: any) {
    yield put(getMilesAwardListFailure(error.message));
  }
}

export function* watchMileageStatementAwardListData(): any {
  yield takeLatest(
    getMilesAwardListDataPending.type,
    getMileageAwardListDataGenerator
  );
}

const MileageAwardDetailHandler = async (payload: any) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
    },
    loader: true,
    isGenericErr: true,
  };
  let mileageStatementPayload = {
    requestNumber: payload.requestNo,
  };
  const response: any = await api.post(
    config.MILEAGE_AWARD_DETAIL,
    mileageStatementPayload,
    data
  );
  response.data.requestNumber = payload.requestNo;
  return response;
};

function* getMileageAwardDetailGenerator(action: PayloadAction<any>): any {
  try {
    const { payload } = action;
    const response: any = yield call(MileageAwardDetailHandler, payload);
    yield put(getMilesAwardDetailSuccess(response.data));
  } catch (error: any) {
    yield put(getMilesAwardDetailFailure(error.message));
  }
}

export function* watchMileageStatementAwardDetailData(): any {
  yield takeLatest(
    getMilesAwardDetailDataPending.type,
    getMileageAwardDetailGenerator
  );
}

const MileageReportDetailHandler = async (payload: any) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
    },
    loader: true,
    isGenericErr: true,
  };
  let mileageStatementPayload = {};
  if (payload.filterData === "ByPeriod") {
    mileageStatementPayload = {
      filterBy: payload.filterData,
      month: payload.monthData,
    };
  } else {
    if (payload?.filterType) {
      mileageStatementPayload = {
        filterBy: payload.filterData,
        startMonth: payload.startMonth,
        endMonth: payload.endMonth,
        month: payload.monthData,
        filterType: payload.filterType,
      };
    } else {
      mileageStatementPayload = {
        filterBy: payload.filterData,
        startMonth: payload.startMonth,
        endMonth: payload.endMonth,
        month: payload.monthData,
      };
    }
  }
  const response = await api.post(
    config.MILEAGE_REPORT,
    mileageStatementPayload,
    data
  );
  return response;
};

function* getMileageReportDetailGenerator(action: PayloadAction<any>): any {
  try {
    const { payload } = action;
    const response: any = yield call(MileageReportDetailHandler, payload);
    yield put(getMilesStatementReportSuccess(response.data));
  } catch (error: any) {
    yield put(getMilesStatementReportFailure(error.message));
  }
}

export function* watchMileageStatementReportData(): any {
  yield takeLatest(
    getMilesStatementReportPending.type,
    getMileageReportDetailGenerator
  );
}

export function* watchMileageStatementData(): any {
  yield takeLatest(
    getMileageStatementPending.type,
    getMileageStatementByPeriodData
  );
}

const MileageStatementDate = async (payload: any) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
    },
    loader: true,
  };
  let mileageStatementPayload = {
    filterBy: payload.filterBy,
    startMonth: payload.startMonth,
    endMonth: payload.endMonth,
    month: payload.month, // need to check
    filterType: payload.filterType,
  };
  const response = await api.post(
    config.MILEAGE_STATEMENT,
    mileageStatementPayload,
    data
  );
  return response;
};

function* getMileageStatementByDateData(action: PayloadAction<any>): any {
  try {
    const { payload } = action;
    const response: any = yield call(MileageStatementDate, payload);
    yield put(getMileageStatementSuccess(response.data));
  } catch (error: any) {
    yield put(getMileageStatementFailure(error.message));
  }
}

export function* watchMileageStatementByDateData(): any {
  yield takeLatest(
    getMileageStatementDatePending.type,
    getMileageStatementByDateData
  );
}
