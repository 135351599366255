import { PaidNomineeOTPData } from "../models/models";
import { config } from "../../../config/global";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  resendNomineeOTPFail,
  resendNomineeOTPRequest,
  resendNomineeOTPSuccess,
  sendNomineeOTPFail,
  sendNomineeOTPRequest,
  sendNomineeOTPSuccess,
  submitNomineeOTPFail,
  submitNomineeOTPRequest,
  submitNomineeOTPSuccess,
} from "../slice/addNomineeSlice";
import { call, put, takeLatest } from "redux-saga/effects";

const SEND_API_URL = config?.PAID_NOMINEE_SEND_OTP;
const RESEND_API_URL = config?.PAID_NOMINEE_RESEND_OTP;
const SUBMIT_API_URL = config?.PAID_NOMINEE_SUBMIT_OTP;

const sendNomineeOTPData = async () => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      Authorization: sessionStorage.getItem("accesstoken"),
      memberId: sessionStorage.getItem("memberId"),
    },
    loader: true,
  };
  const response = await api.post(SEND_API_URL, data);
  return response;
};
const sendNomineeResendOTPData = async () => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      Authorization: sessionStorage.getItem("accesstoken"),
      memberId: sessionStorage.getItem("memberId"),
    },
    loader: true,
  };

  const response = await api.post(RESEND_API_URL, data);

  return response;
};
const submitNomineeOTPData = async (otpData: PaidNomineeOTPData) => {
  const { otp, otpRefKey, nomineeDetails } = otpData;
  const {
    title,
    firstName,
    lastName,
    middleName,
    suffix,
    birthDate,
    royalOrchidPlusMemberId,
  } = nomineeDetails;
  const data = {
    formOfPayment: "MILE",
    salutation: title,
    firstName: firstName,
    lastName: lastName,
    middleName: middleName,
    suffix: suffix,
    dateOfBirth: birthDate,
    ropNumber: royalOrchidPlusMemberId,
    otp: otp,
    otpRefKey: otpRefKey,
  };
  const headerData = {
    headers: {
      "content-type": "application/json",
      Authorization: sessionStorage.getItem("accesstoken"),
      memberId: sessionStorage.getItem("memberId"),
    },
  };
  const response = await api.post(SUBMIT_API_URL, data, headerData);

  return response;
};

function* sendOTPData(): Generator<any, void, any> {
  try {
    const response = yield call(sendNomineeOTPData);
    yield put(sendNomineeOTPSuccess(response.data));
  } catch (error: any) {
    yield put(sendNomineeOTPFail(error));
  }
}
function* resendOTPData(): Generator<any, void, any> {
  try {
    const response = yield call(sendNomineeResendOTPData);
    yield put(resendNomineeOTPSuccess(response.data));
  } catch (error: any) {
    yield put(resendNomineeOTPFail(error));
  }
}
function* submitOTPData(
  action: PayloadAction<PaidNomineeOTPData>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(submitNomineeOTPData, payload);
    yield put(submitNomineeOTPSuccess(response.data));
  } catch (error: any) {
    yield put(submitNomineeOTPFail(error.response.data));
  }
}

export function* watchSendNomineeOTPData(): any {
  yield takeLatest(sendNomineeOTPRequest.type, sendOTPData);
}
export function* watchResendNomineeOTPData(): any {
  yield takeLatest(resendNomineeOTPRequest.type, resendOTPData);
}
export function* watchSubmitNomineeOTPData(): any {
  yield takeLatest(submitNomineeOTPRequest.type, submitOTPData);
}
